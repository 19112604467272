export const GetALlStudentsConst = {
    GET_ALL_STUDENT: "GET_ALL_STUDENT"
}

export const GetALlStudentsByIdConst = {
    GET_ALL_STUDENT_BY_ID: "GET_ALL_STUDENT_BY_ID"
}

export const SelectQuizStudentActionConst = {
    SELECT_UNSELECT_QUIZ_STUDENT: "SELECT_UNSELECT_QUIZ_STUDENT"
}